import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'browser-outline',
    link: '/pages/dashboard',
    home: true,
  },
  {
    title: 'FEATURES',
    group: true,
  },
  {
    title: 'Housekeeping',
    icon: 'home-outline',
    children: [
      {
        title: 'Operating Mode',
        link: '/pages/housekeeping/operating-mode',
      },
      {
        title: 'CDH',
        link: '/pages/housekeeping/cdh',
      },
      {
        title: 'IRAP HVPS',
        link: '/pages/housekeeping/irap-hvps',
      },
      {
        title: 'PA HVPS',
        link: '/pages/housekeeping/pa-hvps',
      },
      {
        title: 'FSW',
        link: '/pages/housekeeping/fsw',
      },
      {
        title: 'DSCB',
        link: '/pages/housekeeping/dscb',
      },
      {
        title: 'DSCB SSD',
        link: '/pages/housekeeping/dscb-ssd',
      },
      {
        title: 'DSCB MCP',
        link: '/pages/housekeeping/dscb-mcp',
      },
      {
        title: 'OPTO LINK',
        link: '/pages/housekeeping/opto-link',
      },
      {
        title: 'LVPS',
        link: '/pages/housekeeping/lvps',
      },
      {
        title: 'DEBUG',
        link: '/pages/housekeeping/debug',
      },
    ],
  },
  {
    title: 'Sensor',
    icon: 'radio-outline',
    children: [
      {
        title: 'Rates',
        link: '/pages/sensor/rates',
      },
      {
        title: 'SSD',
        link: '/pages/sensor/ssd',
      },
    ],
  },
  {
    title: 'Low Latency',
    icon: 'activity-outline',
    children: [
      {
        title: 'Rates',
        link: '/pages/low-latency/rates',
      },
      {
        title: 'Ratios',
        link: '/pages/low-latency/ratios',
      },
    ],
  },
];
