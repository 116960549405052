// import angular modules
import { Component, OnInit, Input, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';

// import classes
import { PlotDefinition } from '@shared/classes/plot.definition';
import { SOService } from '@shared/services/solar-orbiter.service';

var moment = require('moment');

// import plotly
declare var Plotly: any;

@Component({
  selector: 'ngx-discrete-plot',
  templateUrl: './discrete.component.html',
  styleUrls: ['./discrete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    SOService,
  ],
})
export class DiscretePlotComponent implements OnInit {

  @Input() plot: PlotDefinition;
  @ViewChild('discretePlot', { static: true}) discretePlot: ElementRef;

  constructor() { }

  ngOnInit() {

    // initialize range selectorOptions
    var selectorOptions = {
      buttons: [{
        step: 'hour',
        stepmode: 'backward',
        count: 2,
        label: '2h',
      }, {
        step: 'hour',
        stepmode: 'backward',
        count: 1,
        label: '1h',
      }, {
        step: 'minute',
        stepmode: 'backward',
        count: 30,
        label: '30m',
      }, {
        step: 'all',
        label: 'All',
      }]
    }

    // initialize layout
    var layout = {
      height: 360,
      margin: {
        l: 15,
        r: 5,
        t: 20,
        b: 25,
        pad: 5,
      },
      plot_bgcolor: '#F7F9FC',
      autosize: true,
      xaxis: {
        automargin: true,
        gridcolor: '#FFF',
        title: 'TIME (UTC)',
        titlefont: {
          family: 'Roboto, sans-serif',
          size: 14,
        },
      },
      yaxis: {
        automargin: true,
        zeroline: false,
        gridcolor: '#FFF',
        title: this.plot['discrete'].parameter_type.toUpperCase(),
        titlefont: {
          family: 'Roboto, sans-serif',
          size: 14,
        },
      }
    };

    // condi: remove label if missing unit
    if (this.plot['discrete'].unit == undefined || this.plot['discrete'].unit == '') layout.yaxis['title'] = '';

    // initialize configuration
    var config = {
      responsive: true,
      modeBarButtonsToRemove: [
        'autoScale2d',
        'select2d',
        'lasso2d',
        'zoomIn2d',
        'zoomOut2d',
        'hoverClosestCartesian',
        'hoverCompareCartesian',
        'toggleSpikelines',
      ],
      displaylogo: false,
      showEditInChartStudio: false,
    };

    // initialize data
    var data = [];
    var empty = true;

    // switch to populate data
    switch(true) {
      // key-value
      case this.plot['discrete'].subtype == 'key-value': {
        // update axis tick mode & initialize ticks
        layout.yaxis['tickmode'] = 'array';
        layout.yaxis['tickvals'] = [];
        layout.yaxis['ticktext'] = [];
        // get: keys and update axis ticks
        this.plot['discrete'].keys.forEach((pair) => {
          // split key + value
          let key = pair.split(":")[0];
          let val = pair.split(":")[1];
          layout.yaxis['ticktext'].push(key);
          layout.yaxis['tickvals'].push(val);
        });
        // update axis range
        layout.yaxis['range'] = [Math.min(...layout.yaxis['tickvals']) - 1, Math.max(...layout.yaxis['tickvals']) + 1]
        // push data
        data = [{
          y: this.plot.data.map((packet) => packet[this.plot['discrete'].parameter])
        }];
        // stop block
        break;
      };
      // range
      case this.plot['discrete'].subtype == 'range': {
        // update axis label
        if (this.plot['discrete'].unit == undefined || this.plot['discrete'].unit == '') layout.yaxis['title'] = '';
        // update axis range
        layout.yaxis['range'] = [this.plot['discrete']['min'], this.plot['discrete']['max']];
        // push data
        data = [{
          y: this.plot.data.map((packet) => packet[this.plot['discrete'].parameter])
        }];
        // stop block
        break;
      ;}
    };

    // push constant data properties
    data.map((set) => {
      set['x'] = this.plot.data.map((packet) => moment.utc(new Date(packet['TIMETAG_EPOCH_MSEC'])).format());
      set['type'] = 'scatter';
    });

    // check plot for missing data
    data.forEach((set) => {
      // condi: reset empty flag on found data
      if (set.x.length != 0 || set.y.length != 0) {
        empty = false;
      };
    });

    // update missing plot information
    if (empty == true) {
      // update x-axis properties
      layout['xaxis']['zeroline'] = false;
      layout['xaxis']['showline'] = false;
      layout['xaxis']['showgrid'] = false;
      layout['xaxis']['ticks'] = '';
      layout['xaxis']['showticklabels'] = false;
      // remove x-axis properties
      delete layout['xaxis']['title'];
      delete layout['xaxis']['rangeselector'];
      delete layout['xaxis']['rangeslider'];
      // remove y-axis
      layout['yaxis']['visible'] = false;
      // add annotations
      layout['annotations'] = [{
        text: "No data available.",
        xref: 'paper',
        yref: 'paper',
        showarrow: false,
        font: {
          family: 'Roboto, sans-serif',
          size: 28
        }
      }];
    }

    // initialize plot
    this.discretePlot = Plotly.newPlot(
      this.discretePlot.nativeElement,
      data,
      layout,
      config
    );

  }

}