import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError, iif } from 'rxjs';
import { map, take, tap, catchError, concatMap, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // initialize locals
  loggedIn: boolean = null;
  // initialize subjects
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  // create auth0 observable instance of client
  auth0Client$: Observable<Auth0Client> = (from(
    createAuth0Client({
      audience: environment.auth.audience,
      domain: environment.auth.domain,
      client_id: environment.auth.clientID,
      redirect_uri: `${window.location.origin}`,
    })
  )).pipe(shareReplay(1), catchError(err => throwError(err)));
  // authenticated observable
  isAuthenticated$ = this.auth0Client$.pipe(concatMap((client: Auth0Client) => 
    from(client.isAuthenticated())));
  // redirect callback observable
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );
  // user profile observable
  userProfile$ = this.userProfileSubject$.asObservable();

  constructor(private router: Router) { }

  /**
   * getTokenSilently()
   */
  getTokenSilently$(options?): Observable<string> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently(options)))
    );
  }

  /**
   * getUser()
   */
  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap(user => this.userProfileSubject$.next(user))
    );
  }

  /**
   * localAuthSetup()
   */
  localAuthSetup() {
    // initialize auth streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          return this.getUser$();
        }
        // emit 'false' if not authenticated
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe();
  }

  /**
   * handleAuthCallBack()
   */
  handleAuthCallback(): Observable<{ loggedIn: boolean; targetUrl: string }> {
    return of(window.location.search).pipe(
      concatMap(params => 
        iif(() => params.includes('code=') && params.includes('state='),
          this.handleRedirectCallback$.pipe(concatMap(cbRes =>
            this.isAuthenticated$.pipe(take(1),
              map(loggedIn => ({
                loggedIn,
                targetUrl: cbRes.appState && cbRes.appState.targetUrl ? cbRes.appState.targetUrl : '/'
              }))))),
      this.isAuthenticated$.pipe(take(1), map(loggedIn => ({ loggedIn, targetUrl: null}))))));
  }

  /**
   * login()
   */
  login(redirectPath: string = '/pages/dashboard'): Observable<void> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => 
        client.loginWithRedirect({
          connection: 'SHIB-TEST',
          redirect_uri: `${window.location.origin}`,
          appState: { targetUrl: redirectPath }
        })));
  }

  /**
   * logout()
   */
  logout() {
    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.logout({
        client_id: environment.auth.clientID,
        returnTo: `${window.location.origin}/login`
      });
    });
  }

}