// import angular modules
import { Router } from '@angular/router';
import { Component, OnInit, Input, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';

// import classes
import { DashDefinition } from '@shared/classes/dash.definition';
import { SOService } from '@shared/services/solar-orbiter.service';

var moment = require('moment');
declare var Plotly: any;

@Component({
  selector: 'ngx-dashboard-plot',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    SOService,
  ],
})
export class DashboardPlotComponent implements OnInit {

  @Input() plot: DashDefinition;
  @ViewChild('dashboardPlot', { static: true }) dashboardPlot: ElementRef;
  @ViewChild('dashboardPlot', { static: true }) dashboardData: ElementRef;

  // initialize component variables
  outOfRange: boolean = false;

  // initialize subplot variables
  plotName: string;
  subplots: Array<any> = [];

  constructor(private router: Router) {  }

  ngOnInit() {

    // initialize data
    var data = [];
    // initialize subplots
    this.plotName = this.plot.name.toLowerCase().replace(' ', '_');
    this.subplots = this.plot[this.plotName].filter(e => typeof e !== 'string');
    this.subplots.forEach((plot, index) => {
      // initialize variables
      var x, y, z, z_log;
      var empty = true;
      // switch: on plot_type
      switch(plot['plot_type']) {
        case 'continuous':
          // create: datasets
          x = this.plot.data.map((packet) => moment.utc(new Date(packet['TIMETAG_EPOCH_MSEC'])).format());
          // switch: subtype
          switch(plot['continuous']['subtype']) {
            case 'min-max-avg':
              // average trace
              let avgTrace = {
                name: 'Avg',
                x: x,
                y: this.plot.data.map((packet) => packet[plot['continuous']['avg']]),
                type: 'scatter',
                mode: 'lines',
                line: {
                  color: '#1f77b4' // muted blue
                },
                showlegend: false,
                yaxis: `y${index + 1}`
              };
              // condi: check for missing data
              if ((x.length != 0 || avgTrace.y.length != 0) && !avgTrace.y.includes(undefined)) {
                empty = false;
              }
              // push trace to data
              data.push(avgTrace);
              // minimum trace
              let minTrace = {
                name: 'Min',
                x: x,
                y: this.plot.data.map((packet) => packet[plot['continuous']['min']]),
                type: 'scatter',
                mode: 'lines',
                line: {
                  color: '#ff7f0e' // safety orange
                },
                showlegend: false,
                yaxis: `y${index + 1}`
              };
              // condi: check for missing data
              if ((x.length != 0 || minTrace.y.length != 0) && !minTrace.y.includes(undefined)) {
                empty = false;
              }
              // push trace to data
              data.push(minTrace);
              // maximum trace
              let maxTrace = {
                name: 'Max',
                x: x,
                y: this.plot.data.map((packet) => packet[plot['continuous']['max']]),
                type: 'scatter',
                mode: 'lines',
                line: {
                  color: '#2ca02c' // cooked asparagus green
                },
                showlegend: false,
                yaxis: `y${index + 1}`
              };
              // condi: check for missing data
              if ((x.length != 0 || maxTrace.y.length != 0) && !maxTrace.y.includes(undefined)) {
                empty = false;
              }
              // push trace to data
              data.push(maxTrace);
              break;
            default:
              let defaultTrace = {
                name: '',
                x: x,
                y: this.plot.data.map((packet) => packet[plot['continuous']['parameter']]),
                type: 'scatter',
                mode: 'lines',
                line: {
                  color: '#9467bd' // muted purple
                },
                showlegend: false,
                yaxis: `y${index + 1}`
              }
              // condi: check for missing data
              if ((x.length != 0 || defaultTrace.y.length != 0) && !defaultTrace.y.includes(undefined)) {
                empty = false;
              }
              // push trace to data
              data.push(defaultTrace);
              break;
          };
          break;
        case 'discrete':
          // create: datasets
          x = this.plot.data.map((packet) => moment.utc(new Date(packet['TIMETAG_EPOCH_MSEC'])).format());
          // switch: subtype
          switch(plot['discrete']['subtype']) {
            case 'key-value':
              // keyValue trace
              let keyValueTrace = {
                name: '',
                x: x,
                y: this.plot.data.map((packet) => packet[plot['discrete']['parameter']]),
                type: 'scatter',
                mode: 'lines',
                line: {
                  color: '#1f77b4'
                },
                showlegend: false,
                yaxis: `y${index + 1}`
              };
              // condi: check for missing data
              if ((x.length != 0 || keyValueTrace.y.length != 0) && !keyValueTrace.y.includes(undefined)) {
                empty = false;
              }
              // push trace to data
              data.push(keyValueTrace);
              break;
            case 'range':
              // range trace
              let rangeTrace = {
                name: '',
                x: x,
                y: this.plot.data.map((packet) => packet[plot['discrete']['parameter']]),
                type: 'scatter',
                mode: 'lines',
                line: {
                  color: '#1f77b4'
                },
                showlegend: false,
                yaxis: `y${index + 1}`
              };
              // condi: check for missing data
              if ((x.length != 0 || rangeTrace.y.length != 0) && !rangeTrace.y.includes(undefined)) {
                empty = false;
              }
              // push trace to data
              data.push(rangeTrace);
              break;
          }
          break;
        case 'density':
          // create: datasets
          x = this.plot.data.map((packet) => moment.utc(new Date(packet['TIMETAG_EPOCH_MSEC'])).format());
          z = this.plot.data.map((packet) => packet[plot['density']['parameter'].toUpperCase()]);
          // correct z with transpose
          if (!z.includes(undefined) && z != undefined) {
            // condi: ensure z has length
            if (z.length > 0) {
              // transpose
              z = z[0].map((col, i) => z.map(row => row[i]));
              // determine emptiness
              var maxRow = z.map((row) => { return Math.max.apply(Math, row); }),
                  maxVal = Math.max.apply(null, maxRow);
              if (maxVal > 0) empty = false;              
            }
            // create: log scale of density values
            z_log = z.map((sub) => sub.map((x) => x == 0 ? 0 : Math.log10(x)))
            // create: hovertext
            var hovertext = z.map((row, bin) => row.map((rate, time) => {
              return `Time: ${moment.utc(x[time]).format('MMMM Do YYYY, HH:mm:ss')}<br>Bin: ${bin}<br>Rate: ${rate}<br>Logarithmic: ${Math.log10(rate).toFixed(4)}`
            }));
          }
          // initialize tickvals + text
          var tickvals = [];
          var ticktext = [];
          for (var i = plot['density']['z_min'], j = 0; i < plot['density']['z_max']; i = Math.pow(10,j), j++) {
            tickvals.push(j);
            ticktext.push(j == 0 ? 0 : ('10'+`${j}`.sup()));
          }
          // density trace
          var densityTrace = {
            name: '',
            x: x,
            z: z_log,
            showlegend: false,
            yaxis: `y${index + 1}`,
            type: 'heatmap',
            text: hovertext,
            hoverinfo: 'text',
            colorscale: [
              ['0.0', 'rgb(255,255,255)'],
              ['0.066666666667', 'rgb(0,0,131)'],
              ['0.133333333667', 'rgb(0,32,151)'],
              ['0.200000000667', 'rgb(0,76,177)'],
              ['0.266666667667', 'rgb(2,126,199)'],
              ['0.333333334667', 'rgb(3,189,226)'],
              ['0.400000001667', 'rgb(5,239,248)'],
              ['0.466666668667', 'rgb(65,255,193)'],
              ['0.533333335667', 'rgb(130,255,128)'],
              ['0.600000002667', 'rgb(211,255,45)'],
              ['0.666666669667', 'rgb(255,234,0)'],
              ['0.733333336667', 'rgb(253,169,0)'],
              ['0.800000003667', 'rgb(252,86,0)'],
              ['0.866666670667', 'rgb(250,21,0)'],
              ['0.933333337667', 'rgb(191,0,0)'],
              ['1.0', 'rgb(128,0,0)']
            ],
            colorbar: {
              tickvals: tickvals,
              ticktext: ticktext,
              len: 0.078,
              y: this.getSubplotDomain(this.subplots.length, index)[0] + 0.033
            },
            zmin: 0,
            zmax: Math.log10(plot['density']['z_max']) || 3,
            zsmooth: 'best'
          }
          // push trace to data
          data.push(densityTrace);
          break;
        default:
          console.log('UNKNOWN: ' + plot)
          break;
      }
      // update: add empty variable to plot
      plot['empty'] = empty;
    });

    // initialize layout
    var layout = {
      height: (this.subplots.length > 5 ? (200 * this.subplots.length) : (220 * this.subplots.length)),
      margin: {
        l: 90,
        r: 5,
        t: 50,
        b: 25,
        pad: 10,
      },
      plot_bgcolor: '#F7F9FC',
      xaxis: {
        automargin: true,
        gridcolor: '#FFF',
        title: 'TIME (UTC)',
        titlefont: {
          family: 'Roboto, sans-serif',
          size: 14
        },
      },
      grid: {
        rows: this.subplots.length,
        columns: 1,
        roworder: 'bottom to top',
      },
    };

    // initialize y-axes
    for(var i = 0; i < this.subplots.length; i++) {
      // initialize axis name
      let axis = i == 0 ? 'yaxis' : ('yaxis' + (i + 1));
      // update: layout
      layout[axis] = {
        automargin: true,
        gridcolor: '#FFF',
        domain: this.getSubplotDomain(this.subplots.length, i)
      };
    };

    // inintialize annotations
    layout['annotations'] = [
      ...this.createSubplotTitles(this.subplots),
      ...this.createSubplotYAxisTitles(this.subplots),
      ...this.createMissingPlotAnnotations(this.subplots)
    ];

    // update: perform a number of changes to the layout based on subplots
    layout = this.updateLayout(this.subplots, layout);

    // initialize configuration
    var config = {
      responsive: true,
      modeBarButtonsToRemove: [
        'select2d',
        'lasso2d',
        'zoomIn2d',
        'zoomOut2d',
        'hoverClosestCartesian',
        'hoverCompareCartesian',
        'toggleSpikelines',
      ],
      displaylogo: false,
      showEditInChartStudio: false,
    };

    // initialize plot
    this.dashboardPlot = Plotly.newPlot(
      this.dashboardPlot.nativeElement,
      data,
      layout,
      config
    );

    // onClick: redirect to subsystem + plot
    this.dashboardData.nativeElement.on('plotly_click', (data) => {
      // initialize variables
      var yAxisID = Number(data['points'][0]['data']['yaxis'].split('').pop());
      var plot    = this.subplots[yAxisID - 1];
      // navigation
      this.router.navigateByUrl(`/pages/${plot['packet_type']}/${plot['subsystem']}?selection=${plot['_id']}`);
    });

  }

  getSubplotDomain(numberOfSubplots: number, index: number): Array<number> {
    // initialize variables
    var n = numberOfSubplots;
    var spacing = numberOfSubplots > 5 ? 0.05 : 0.085;
    // calculate variables
    var tot_space = (spacing * (n - 1));
    var tot_dom = ((1 - tot_space) / n);
    // initialize domains
    var start = (index * spacing) + (index * tot_dom);
    var stop = ((index * spacing) + (index * tot_dom) + tot_dom);
    // determine spacing
    return [
      start,
      stop
    ]
  }

  createSubplotTitles(plotDetails: any): Array<any> {
    // initialize variables
    var spacing  = this.subplots.length > 5 ? (0.05 / 2) : (0.085 / 2);
    var annotations = [];
    // loop: plotDetails
    plotDetails.forEach((plot, index) => {
      // complete hack for title spacing
      var factor = index == 0 ? (spacing / 2) : (spacing * (1 / (index + 1)));
          factor = (index != 0 && this.subplots.length == 3) ? -(spacing / 3) : factor;
          factor = (index != 0 && this.subplots.length == 4) ? (spacing / 25) : factor;
      // push: annotation
      annotations.push({
        text: `<b>${plot['name'] || ''}</b>`,
        font: {
          family: 'Roboto, sans-serif',
          size: 12
        },
        showarrow: false,
        align: 'center',
        x: 0.5,
        y: this.getSubplotDomain(this.subplots.length, index)[1] + (spacing - factor),
        xref: 'paper',
        yref: 'paper',
      });
    });
    return annotations;
  }

  createSubplotYAxisTitles(plotDetails: any): Array<any> {
    // initialize variables
    var annotations = [];
    var currentUnit = '', previousUnit = '', tempUnitName = '';
    var lastStop = 0;
    // loop: plotDetails
    plotDetails.forEach((plot, index) => {
      // switch: plot_type
      switch(plot['plot_type']) {
        case 'continuous':
          // initialize unit
          currentUnit = plot['continuous']['unit'];
          // condi: check previousUnit
          if ((currentUnit != previousUnit && previousUnit != '') || index == plotDetails.length - 1) {
            // initialize text + y-coord
            var text = (tempUnitName == '' ? previousUnit : (tempUnitName.slice(-1) == 's' ? tempUnitName.substring(0, tempUnitName.length - 1) : tempUnitName)).toUpperCase();
                text =  tempUnitName == '' ? text == 'EQ BIN' ? 'E/Q' : text == 'ELEVATION BIN' ? 'Elevation (CH)' : text : text;
                text =  tempUnitName == '' ? text : text + ` (${previousUnit})`;
            var    y = (this.getSubplotDomain(this.subplots.length, ((index == plotDetails.length - 1) ? index : (index - 1)))[1] + lastStop) / 2;
            // push: annotation
            annotations.push({
              text: text,
              font: {
                family: 'Roboto, sans-serif',
                size: 14
              },
              showarrow: false,
              align: 'center',
              x: -0.1575,
              y: y,
              xref: 'paper',
              yref: 'paper',
              textangle: -90
            });
            // update: lastStop
            lastStop = this.getSubplotDomain(this.subplots.length, index)[1];
          }
          // update: previousUnit + tempUnitName
          tempUnitName = plot['continuous']['parameter_type'];
          previousUnit = currentUnit;
          break;
        case 'density':
          // intialize unit
          currentUnit = plot['density']['y_unit'];
          // condi: check previousUnit
          if ((currentUnit != previousUnit && previousUnit != '') || index == plotDetails.length - 1) {
            // initialize text + y-coord
            var text = (previousUnit == 'EQ bin' ? 'E/Q' : previousUnit == 'Elevation bin' ? 'Elevation (CH)' : previousUnit).toUpperCase();
            var    y = (this.getSubplotDomain(this.subplots.length, ((index == plotDetails.length - 1) ? index : (index - 1)))[1] + lastStop) / 2;
            // condi: hack on rates dashboard
            if (this.plotName == 'rates') {
              switch (text) {
                case 'SSD NUMBER':
                  y = y - 0.0625;
                  break;
                case 'ELEVATION (CH)':
                  y = y - 0.035;
                  break;
              }
            }
            // push: annotation
            annotations.push({
              text: text,
              font: {
                family: 'Roboto, sans-serif',
                size: 14
              },
              showarrow: false,
              align: 'center',
              x: -0.1575,
              y: y,
              xref: 'paper',
              yref: 'paper',
              textangle: -90
            });
            // update: lastStop
            lastStop = this.getSubplotDomain(this.subplots.length, index)[1];
          }
          // update: previousUnit + tempUnitName
          tempUnitName = '';
          previousUnit = currentUnit;
          break;
      };
    });
    return annotations;
  }

  updateLayout(plotDetails: any, layout: any) {
    // loop: plotDetails
    plotDetails.forEach((plot, index) => {
      // initialize variables
      var axis = index == 0 ? 'yaxis' : `yaxis${index + 1}`;
      // condi: discrete operations
      if (plot['plot_type'] == 'discrete') {
        // switch on subType
        switch(plot['discrete']['subtype']) {
          case 'key-value':
            // update axis tick mode & initialize ticks
            layout[axis]['tickmode'] = 'array';
            layout[axis]['tickvals'] = [];
            layout[axis]['ticktext'] = [];
            // get: keys and update axis ticks
            plot['discrete']['keys'].forEach((pair) => {
              // split key + value
              let key = pair.split(":")[0];
              let val = pair.split(":")[1];
              // push to y-axis
              layout[axis]['ticktext'].push(key);
              layout[axis]['tickvals'].push(val);
            });
            // update axis range
            layout[axis]['range'] = [Math.min(...layout[axis]['tickvals']) - 1, Math.max(...layout[axis]['tickvals']) + 1];
            break;
          case 'range':
            // update axis range
            layout[axis]['range'] = [plot['discrete']['min'], plot['discrete']['max']];
            break;
        }
      }
      // condi: empty operations
      if (plot['empty'] == true) {
        // remove axis visibility
        layout[axis]['visible'] = false;
        // condi: check for x-ref
        if (index == plotDetails.length - 1) {
          layout['xaxis']['visible'] = false;
        }
      }
    });
    return layout;
  }

  createMissingPlotAnnotations(plotDetails: any): Array<any> {
    // initialize variables
    var annotations = [];
    // loop: plotDetails
    plotDetails.forEach((plot, index) => {
      // initialize variables
      var axis = `y${index + 1}`;
      // condi: check for missing data
      if (plot['empty'] == true) {
        // push annotation
        annotations.push({
          text: 'No data available.',
          xref: 'paper',
          yref: axis,
          showarrow: false,
          font: {
            family: 'Roboto, sans-serif',
            size: 20
          }
        });
      };
    });
    return annotations;
  }

};