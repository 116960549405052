import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})

export class FilterPipe implements PipeTransform {

  transform(items: any[], filterType: string): any[] {
    // condi: no items, return empty
    if (!items) return [];
    // reduce case sensitivity
    filterType = filterType.toLowerCase();
    // condi: no filter or "all", return everything
    if (!filterType || filterType == 'all') return items;
    // condi: return on filter
    return items.filter(item => {
      // filter based on properties array
      return item.properties.includes(filterType);
    });
  }

}
