export const environment = {
  envName: 'prod',
  routeSWAHIS: 'https://prod-swa-his-api-sohis.webplatformsunpublished.umich.edu/swa-his',
  production: false,
  auth: {
    audience: 'solar-orbiter',
    clientID: 'b9ijV0I2WSvYeJ6jeK3lrJtKcuH4VPOF',
    domain: 'dev-h32zmhmt.auth0.com',
  }
};
