/**
 * PlotDefinition: class used to generate plot definitions
 */
export class PlotDefinition {

  // general information
  _id: string;
  name: string;
  description: string;

  // plot details
  packet_type: string;
  subsystem: string;
  plot_type: string;

  // data
  data: Array<Object>;

}
