import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { PagesComponent } from './pages.component';

import { RefreshComponent } from '../shared/components/_refresh/refresh.component';

import { DashboardComponent } from './dashboard/dashboard.component';

// authentication
import { AuthGuard } from '@shared/services/auth.guard';

// housekeeping
import { CDHComponent } from './housekeeping/cdh/cdh.component';
import { OperatingModeComponent } from './housekeeping/operating-mode/operating-mode.component';
import { IRAPHVPSComponent } from './housekeeping/irap-hvps/irap-hvps.component';
import { PAHVPSComponent } from './housekeeping/pa-hvps/pa-hvps.component';
import { FSWComponent } from './housekeeping/fsw/fsw.component';
import { DSCBComponent } from './housekeeping/dscb/dscb.component';
import { DSCBSSDComponent } from './housekeeping/dscb-ssd/dscb-ssd.component';
import { DSCBMCPComponent } from './housekeeping/dscb-mcp/dscb-mcp.component';
import { OPTOLinkComponent } from './housekeeping/opto-link/opto-link.component';
import { LVPSComponent } from './housekeeping/lvps/lvps.component';
import { DebugComponent } from './housekeeping/debug/debug.component';

// sensor
import { SensorRatesComponent } from './sensor/rates/rates.component';
import { SensorSSDComponent } from './sensor/ssd/ssd.component';

// low-latency
import { LowLatencyRatesComponent } from './low-latency/rates/rates.component';
import { LowLatencyRatiosComponent } from './low-latency/ratios/ratios.component';

// miscellaneous
import { MiscellaneousModule } from './miscellaneous/miscellaneous.module';
import { NotFoundComponent } from './miscellaneous/not-found/not-found.component';


const routes: Routes = [{
  path: '',
  component: PagesComponent,
  children: [
    // refresh route
    {
      path: 'refresh',
      component: RefreshComponent,
      canActivate: [AuthGuard],
    },
    // dashboard route
    {
      path: 'dashboard',
      component: DashboardComponent,
      canActivate: [AuthGuard],
    },
    // housekeeping routes
    {
      path: 'housekeeping/operating-mode',
      component: OperatingModeComponent,
      canActivate: [AuthGuard],
    },
    {
      path: 'housekeeping/cdh',
      component: CDHComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'housekeeping/irap-hvps',
      component: IRAPHVPSComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'housekeeping/pa-hvps',
      component: PAHVPSComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'housekeeping/fsw',
      component: FSWComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'housekeeping/dscb',
      component: DSCBComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'housekeeping/dscb-ssd',
      component: DSCBSSDComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'housekeeping/dscb-mcp',
      component: DSCBMCPComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'housekeeping/opto-link',
      component: OPTOLinkComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'housekeeping/lvps',
      component: LVPSComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'housekeeping/debug',
      component: DebugComponent,
      canActivate: [AuthGuard]
    },
    // sensor routes
    {
      path: 'sensor/rates',
      component: SensorRatesComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'sensor/ssd',
      component: SensorSSDComponent,
      canActivate: [AuthGuard]
    },
    // low-latency routes
    {
      path: 'low-latency/rates',
      component: LowLatencyRatesComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'low-latency/ratios',
      component: LowLatencyRatiosComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'miscellaneous',
      loadChildren: () => MiscellaneousModule,
    },
    {
      path: '',
      redirectTo: 'dashboard',
      pathMatch: 'full',
    },
    {
      path: '**',
      component: NotFoundComponent,
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}
