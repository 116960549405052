// import angular modules
import { Component, OnInit, Input, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';

// import classes
import { PlotDefinition } from '@shared/classes/plot.definition';
import { PlotService } from '@shared/services/plot.service';

var moment = require('moment');

// import plotly
declare var Plotly: any;

@Component({
  selector: 'ngx-density-plot',
  templateUrl: './density.component.html',
  styleUrls: ['./density.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    PlotService,
  ],
})
export class DensityPlotComponent implements OnInit {

  @Input() plot: PlotDefinition;
  @ViewChild('densityPlot', { static: true}) densityPlot: ElementRef;

  // initialize component variables
  inconsistent: boolean = false;
  parameters: Array<String> = [];

  constructor(private plotService: PlotService) { }

  ngOnInit() {

    // initialize layout
    var layout = {
      height: 360,
      margin: {
        l: 20,
        r: 5,
        t: 20,
        b: 25,
        pad: 5,
      },
      plot_bgcolor: '#F7F9FC',
      autosize: true,
      xaxis: {
        automargin: true,
        gridcolor: '#FFF',
        title: 'TIME (UTC)',
        titlefont: {
          family: 'Roboto, sans-serif',
          size: 14,
        },
      },
      yaxis: {
        automargin: true,
        gridcolor: '#FFF',
        title: (this.plot['density']['y_unit'] == 'EQ bin' ? 'E/Q' : this.plot['density']['y_unit'] == 'Elevation bin' ? 'Elevation (CH)' : '').toUpperCase(),
        titlefont: {
          family: 'Roboto, sans-serif',
          size: 14,
        },
      },
    }

    // initialize configuration
    var config = {
      responsive: true,
      modeBarButtonsToRemove: [
        'select2d',
        'lasso2d',
        'zoomIn2d',
        'zoomOut2d',
        'hoverClosestCartesian',
        'hoverCompareCartesian',
        'toggleSpikelines',
      ],
      displaylogo: false,
      showEditInChartStudio: false,
    }

    // initialize low-latency configuration
    if (this.plot['packet_type'] == 'low-latency') {
      // create: low-latency configuration
      var lowLatencyConfiguration = this.plotService.configureLowLatency(this.plot);
      // update: plotName
      this.plot['name'] = this.plot['name'] + ': ' + lowLatencyConfiguration['plotName'];
      // update: parameters
      this.parameters = lowLatencyConfiguration['parameters'];
      // set: shapes
      layout['shapes'] = lowLatencyConfiguration['shapes'];
      //condi: update inconsistent variables
      this.inconsistent = lowLatencyConfiguration['shapes'].length > 0 ? true : false;
    }

    // initialize emptiness
    var empty = true;

    // initialize x
    var x = this.plot.data.map((packet) => moment.utc(new Date(packet['TIMETAG_EPOCH_MSEC'])).format());
    // initialize z & transpose
    var z = this.plot.data.map((packet) => packet[this.plot['density'].parameter.toUpperCase()]);
      // condi: skip undefined arrays
      if (!z.includes(undefined) && z != undefined) {
        // condi: ensure z length
        if (z.length > 0) {
          // transpose z
          z = z[0].map((col, i) => z.map(row => row[i]));
          // determine emptiness
          var maxRow = z.map((row) => { return Math.max.apply(Math, row); }),
              maxVal = Math.max.apply(null, maxRow);
        }
        // create: log scale of density values
        var z_log = z.map((sub) => sub.map((x) => x == 0 ? 0 : Math.log10(x)))
        // create: hovertext
        var hovertext = z.map((row, bin) => row.map((rate, time) => {
          return `Time: ${moment.utc(x[time]).format('MMMM Do YYYY, HH:mm:ss')}<br>Bin: ${bin}<br>Rate: ${rate}<br>Logarithmic: ${Math.log10(rate).toFixed(4)}`
        }));
      }
    // initialize tickvals + text (supports 10k vs. 100k)
    var tickvals = [];
    var ticktext = [];
    for (var i = this.plot['density'].z_min, j = 0; i < this.plot['density'].z_max; i = Math.pow(10,j), j++) {
      tickvals.push(j);
      ticktext.push(j == 0 ? 0 : ('10'+`${j}`.sup()));
    }

    if (maxVal > 0) empty = false;

    // initialize data
    var data = [{
      x: x,
      z: z_log,
      type: 'heatmap',
      text: hovertext,
      hoverinfo: 'text',
      colorscale: [
        ['0.0', 'rgb(255,255,255)'],
        ['0.066666666667', 'rgb(0,0,131)'],
        ['0.133333333667', 'rgb(0,32,151)'],
        ['0.200000000667', 'rgb(0,76,177)'],
        ['0.266666667667', 'rgb(2,126,199)'],
        ['0.333333334667', 'rgb(3,189,226)'],
        ['0.400000001667', 'rgb(5,239,248)'],
        ['0.466666668667', 'rgb(65,255,193)'],
        ['0.533333335667', 'rgb(130,255,128)'],
        ['0.600000002667', 'rgb(211,255,45)'],
        ['0.666666669667', 'rgb(255,234,0)'],
        ['0.733333336667', 'rgb(253,169,0)'],
        ['0.800000003667', 'rgb(252,86,0)'],
        ['0.866666670667', 'rgb(250,21,0)'],
        ['0.933333337667', 'rgb(191,0,0)'],
        ['1.0', 'rgb(128,0,0)']
      ],
      colorbar: {
        tickvals: tickvals,
        ticktext: ticktext
      },
      zmin: 0,
      zmax: Math.log10(this.plot['density'].z_max) || 3,
      zsmooth: 'best'
    }];

    // update missing polot information
    if (empty == true) {
      // remove x-axis & y-axis
      layout['xaxis']['visible'] = false;
      layout['yaxis']['visible'] = false;
      // add annotations
      layout['annotations'] = [{
        text: "No data available.",
        xref: 'paper',
        yref: 'paper',
        showarrow: false,
        font: {
          family: 'Roboto, sans-serif',
          size: 28
        }
      }];
      // remove data
      data = [];
    }

    // initialize plot
    this.densityPlot = Plotly.newPlot(
      this.densityPlot.nativeElement,
      data,
      layout,
      config
    );

  }

}