// import angular modules
import { Component, ElementRef, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbDateService, NbToastrService } from '@nebular/theme';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SOService } from '@shared/services/solar-orbiter.service';
import { PlotService } from '@shared/services/plot.service';

var moment = require('moment');

@Component({
  selector: 'ngx-preview-control',
  templateUrl: './preview-control.component.html',
  styleUrls: ['./preview-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PreviewControlComponent implements OnInit {

  @ViewChild("previewDateElm", {static: true}) previewDateElm: ElementRef;

  // timeBlock variables
  timeBlockForm: FormGroup;

  // searchDate variables
  ready: boolean;
  searchStartDate: Date;
  searchEndDate: Date;
  searchOrigin: String;
  searchResolution: number;
  searchDateFormatted: String;
  searchTimeFormatted: String;

  // preview variables
  previewResolution

  // filter variables
  filterType: Object;
  filterButtons = [
    {
      name: 'All',
      status: 'primary',
    },
    {
      name: 'Voltages',
      status: 'primary',
    },
    {
      name: 'Currents',
      status: 'primary',
    },
    {
      name: 'Temperature',
      status: 'primary',
    },
    {
      name: 'Errors',
      status: 'primary',
    },
    {
      name: 'States/Logic',
      status: 'primary',
    },
    {
      name: 'Safing Status',
      status: 'primary',
    },
    {
      name: 'Other',
      status: 'primary',
    },
  ]

  // route variables
  routeSubscription: any;
  location: string;

  // toast variables
  types: NbComponentStatus[] = [
    'primary',
    'success',
    'info',
    'warning',
    'danger',
  ];

  constructor(private router: Router,
              protected dateService: NbDateService<Date>,
              private toastrService: NbToastrService,
              private solarService: SOService,
              public plotService: PlotService,
              private fb: FormBuilder) { }

  ngOnInit() {
    // initialize filterType
    this.plotService.searchFilter$
      .subscribe((data) => {
        this.filterType = data;
      })
    // initialize searchDate
    this.plotService.searchDate$
      .subscribe((data) => {
        // initialize variables
        this.ready = data.ready;
        this.searchStartDate = new Date(data.searchDate);
        this.searchStartDate.setHours(
          (this.searchStartDate.getHours() - this.plotService.timeResolution[data.resolution]['resolution'])
        );
        this.searchEndDate = new Date(data.searchDate);
        this.searchOrigin = data.origin;
        this.searchResolution = data.resolution;
        // set: preview control element
        this.searchDateFormatted = moment.utc(this.searchEndDate).format('MMM D, YYYY');
        this.searchTimeFormatted = moment.utc(this.searchStartDate).format("HH:mm") + ' - ' + moment.utc(this.searchEndDate).format("HH:mm");
        this.previewDateElm.nativeElement.value = this.searchDateFormatted;
        this.previewResolution = this.plotService.timeResolution[this.searchResolution];
        // create: formGroup
        this.timeBlockForm = this.fb.group({
          timeBlock: ['', [
            Validators.required,
            Validators.pattern(/^(([0-1]?[0-9]|2[0-3]):[0-5][0-9] - ([0-1]?[0-9]|2[0-3]):[0-5][0-9])|(([0-1]?[0-9]|2[0-3]):[0-5][0-9])$/g)
          ]]
        }, { validator: (timeBlock) => {
          // custom validation
        }});
        this.timeBlockForm.get('timeBlock').setValue(this.searchTimeFormatted);
        setTimeout(() => {
          this.timeBlockForm.get('timeBlock').setErrors(null);
        });
        // get: subsystem
        const subsystem = this.router.url.includes('housekeeping') ? 'housekeeping' : this.router.url.includes('sensor') ? 'sensor' : this.router.url.includes('low-latency') ? 'low-latency' : null;
        // condi: initialize latest date
        if (!this.ready || this.searchOrigin != subsystem) this.initializeSearchDate(subsystem);
      });
  }

  initializeSearchDate(subsytem: string): void {
    // switch: on subsystem
    switch(subsytem) {
      case 'housekeeping':
        this.solarService.getLatestHousekeeping()
          .subscribe((date) => {
            this.updateSearchDate({
              ready: true,
              origin: 'housekeeping',
              searchDate: new Date(date),
              resolution: this.searchResolution
            });
          });
        break;
      case 'sensor':
        this.solarService.getLatestSensor()
          .subscribe((date) => {
            this.updateSearchDate({
              ready: true,
              origin: 'sensor',
              searchDate: new Date(date),
              resolution: this.searchResolution
            });
          });
        break;
      case 'low-latency':
        this.solarService.getLatestLowLatency()
          .subscribe((date) => {
            this.updateSearchDate({
              ready: true,
              origin: 'low-latency',
              searchDate: new Date(date),
              resolution: this.searchResolution
            });
          });
        break;
    }
  }

  updateSearch(action?: string, index?: number): void {
    // condi: check timeBlock validation
    if (!this.timeBlockForm.valid) {
      this.showToast(this.types[4], `Preview Control Error`, `Invalid submission due to Time Block.`);
      // return;
    }
    // initialize time & date variables
    var searchDate = new Date(this.searchEndDate);
    var timeBlock = this.timeBlockForm.get('timeBlock').value.split(' - ');
    var timeSplit = timeBlock.length == 2 ? timeBlock[1].split(':') : timeBlock[0].split(':');
    var timeResolution = index != undefined ? this.plotService.timeResolution[index]['resolution'] : this.previewResolution['resolution'];
    // switch on action
    switch(action) {
      case 'forward':
        searchDate.setHours(
          (searchDate.getHours() + timeResolution)
        );
        break;
      case 'backward':
        searchDate.setHours(
          (searchDate.getHours() - timeResolution)
        );
        break;
      default:
        searchDate.setUTCHours(
          timeSplit[0],
          timeSplit[1],
        );
        break;
    }
    // update searchDate
    this.updateSearchDate({
      ready: true,
      origin: this.searchOrigin,
      searchDate: searchDate,
      resolution: index != undefined ? index : this.searchResolution
    });
    // reload component
    this.location = this.router.url;
    this.router.navigateByUrl(`/pages/refresh`, { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(`${this.location}`);
    });
  }

  updateSearchDate(data: any): void {
    this.plotService.updateSearchDate({
      ready: data.ready,
      origin: data.origin,
      searchDate: data.searchDate,
      resolution: data.resolution
    });
  }

  updateSearchFilter(data: any): void {
    this.plotService.updateSearchFilter({
      name: data.name,
      status: data.status
    })
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    // initialize configuration
    const config = {
      status: type,
      destroyByClick: true,
      duration: 5000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
      preventDuplicates: false,
    }

    // show service
    this.toastrService.show(
      body,
      title,
      config
    )
  };

}
