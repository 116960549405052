import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable, of } from 'rxjs';
import { share } from 'rxjs/operators';
import { PlotDefinition } from '../classes/plot.definition';

@Injectable({
  providedIn: 'root',
})
export class PlotService {
  public dashboardDate$ = new BehaviorSubject({
    ready: false,
    dashboardDate: new Date('2020-01-03T23:59:00Z'),
    resolution: 4
  });
  public searchDate$ = new BehaviorSubject({
    ready: false,
    origin: 'housekeeping',
    searchDate: new Date('2020-05-05T17:59:00Z'),
    resolution: 4,
  });
  public searchFilter$ = new BehaviorSubject({
    name: 'All',
    status: 'primary'
  });

  // time resolution (hours)
  public timeResolution = [
    {
      display: '1 hr',
      resolution: 1
    },
    {
      display: '4 hrs',
      resolution: 4
    },
    {
      display: '8 hrs',
      resolution: 8
    },
    {
      display: '12 hrs',
      resolution: 12
    },
    {
      display: '24 hrs',
      resolution: 24
    },
  ];

  /**
   * Updates global dashboard date state for plot preview
   * @param dashboardDate  housekeeping/sensor dates & resolution
   */
  updateDashboardDate(dashboardDate) {
    this.dashboardDate$.next(dashboardDate);
  }

  /**
   * Updates global search date state for plot preview
   * @param searchDate  day, time & resolution
   */
  updateSearchDate(searchDate) {
    this.searchDate$.next(searchDate);
  }

  /**
   * Updates global search filter state for plot previews
   * @param searchFilter name & status properties
   */
  updateSearchFilter(searchFilter) {
    this.searchFilter$.next(searchFilter);
  };

  /**
   * Subscribe to changes to searchFilter events
   * @returns Observable<{name: string, status: string}
   */
  onSearchFilterChange(): Observable<{ name: string, status: string}> {
    return this.searchFilter$.pipe(share());
  }

  /**
   * Configures LowLatency plot handling based on data set.\
   * @returns <Object> {plotName, annotation}
   */
  configureLowLatency(plot: any) {
    // initialize variables
    var plotName = '';
    var plotIdentifier = '';
    var plotNames = [];
    var shapes = [];
    // condi: determine target name + flatten plotNames
    plotIdentifier = plot['name'].includes('1') ? 'SPECTRA1_NAME' : plot['name'].includes('2') ? 'SPECTRA2_NAME' : '';
    plotName = plot['data'][plot['data'].length - 1][plotIdentifier]['name'];
    plot['data'].forEach(elm => {
      // initialize variables
      var plotName = elm[plotIdentifier]['name'];
      // condi: filter duplicates
      (!plotNames.includes(plotName)) && plotNames.push(plotName);
    });
    // create: annotations
    if (plotNames.length > 1) {
      // loop: create shapes that don't match
      plot['data'].forEach(elm => {
        // condi: match current
        if (elm[plotIdentifier]['name'] != plotName) {
          shapes.push({
            type: 'line',
            xref: 'x',
            yref: 'paper',
            x0: elm['TIMETAG_EPOCH_MSEC'],
            y0: 0,
            x1: elm['TIMETAG_EPOCH_MSEC'],
            y1: 1,
            line: {
              color: '#ff3d71',
              width: 4,
              dash: 'dash'
            }
          })
        }
      });
    }
    // return object
    return {
      plotName: plotName,
      shapes: shapes,
      parameters: plotNames
    }
  }

  /**
   * Binds the data object to each plot definition
   * @returns <PlotDefinition> + Data
   */
  bindData(plotDefinitions: Array<any>, data: Array<any>, type?: string) {
    return plotDefinitions.map((obj) => {
      obj['data'] = data;
      return obj;
    });
  }

  /**
   * Binds the dashboard definition with packet data and plot definitions
   * @returns <DashDefinition> + Data(Housekeeping || Sensor)
   */
  bindDashData(dashboardDefinitions: Array<any>, plotDefinitions: Array<any>, housekeepingData: Array<any>, sensorData: Array<any>) {
    return dashboardDefinitions.map((obj) => {
      // initialize definition name
      var defName = obj.name.toLowerCase().replace(' ','_');
      // loop: parameters & replace with definition
      for (var i = 0; i < obj[defName].length; i++) {
        let param = obj[defName][i];
        // replace: param with definition
        obj[defName][i] = (plotDefinitions.filter((elm) => {
          return elm.parameter == param;
        }))[0] || param;
      }
      // @PlotlyJS Bug: reverse order of parameters
      obj[defName].reverse();
      // switch on packet_type
      switch(obj['packet_type']) {
        case 'housekeeping':
          obj['data'] = housekeepingData;
          break;
        case 'sensor':
          obj['data'] = sensorData;
          break;
      }
      return obj;
    });
  }

  /**
   * Filters plot definitions to specified plot type and binds data
   * @returns <PlotDefinition> + Data
   */
  filterAndBind(plotDefinitions: Array<any>, plotType: string, data: Array<any>) {
    return plotDefinitions.filter((el) => {
      return el.plot_type == plotType;
    }).map((obj) => {
      obj['data'] = data;
      return obj;
    });
  }

  /**
   * Consolidates parameters for all plot types into array
   * @returns Array<Parameters>
   */
  getPlotParameters(plotDefinitions: Array<any>) {
    // initialize local var
    var plotParameters = [];
    // loop: iterate through plotDefinitions
    plotDefinitions.forEach((def) => {
      // switch: plotType
      switch(true) {
        case def.plot_type == 'continuous': {
          // condi: check min, max, average
          if (def['continuous']['min'] && def['continuous']['max'] && def['continuous']['avg']) {
            plotParameters.push(def['continuous']['min']);
            plotParameters.push(def['continuous']['max']);
            plotParameters.push(def['continuous']['avg']);
          } else {
            plotParameters.push(def['continuous']['parameter']);
          };
          break;
        };
        case def.plot_type == 'discrete': {
          plotParameters.push(def['discrete']['parameter']);
          break;
        };
        case def.plot_type == 'density': {
          plotParameters.push(def['density']['parameter']);
          break;
        };
      };
      // switch: subsystem
      switch(true) {
        case def.subsystem == 'dashboard': {
          // initialize types
          const types = [
            'high_voltage',
            'low_voltage',
            'temperature',
            'states',
            'rates'
          ];
          // loop: types and push parameters
          types.forEach((type) => {
            // condi: check if type exists
            if (def[type] != undefined) {
              // loop: parameters
              def[type].forEach((param) => {
                plotParameters.push(param);
              });
            };
          });
          break;
        };
      }
    });
    // filter: remove duplicates
    return plotParameters.filter((el, index, self) => {
      return index === self.indexOf(el);
    });
  };

  /**
   * Loads the next four plot definitions into the buffer
   * @returns 
   */
  load(previewSize: number, plotDefinitions: Array<any>): Observable<PlotDefinition[]> {
    // initialize starting index
    const startIndex = 0;
    // return plot definitions to add
    return plotDefinitions == undefined ? of([]): of(plotDefinitions.splice(startIndex,previewSize));
  };

}