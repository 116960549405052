import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})

export class SearchPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    // if no items, return an empty array
    if(!items) return [];
    // if no search, return everything
    if(!searchText) return items;
    // reduce case sensitivity
    searchText = searchText.toLowerCase();
    // return the filtered items
    return items.filter(item => {
      // filters on name
      return item.name.toLowerCase().includes(searchText);
    });
  }

}
