import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import { NbSearchService } from '@nebular/theme';

import { SensorComponent } from '../_main/sensor.component';

import { SOService } from '@shared/services/solar-orbiter.service';
import { PlotService } from '@shared/services/plot.service';

@Component({
  selector: 'ngx-sensor-ssd',
  styleUrls: ['../_main/sensor.component.scss'],
  templateUrl: '../_main/sensor.component.html',
  providers: [
    SOService,
  ],
})
export class SensorSSDComponent extends SensorComponent {

  constructor(searchService: NbSearchService,
              router: Router,
              activeRoute: ActivatedRoute,
              solarService: SOService,
              plotService: PlotService)
  {
    super('ssd-rates', searchService, router, activeRoute, solarService, plotService);
  }

}
