import { Component } from '@angular/core';

var moment = require('moment');

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span>
      <a href="https://clasp.engin.umich.edu/" target="_blank">
        <img style='width: 35%' src='/assets/images/coe_clasp.png'>
      </a>
      <div style='float: right; line-height: 36.72px'>
        Created by <b><a href="http://solar-heliospheric.engin.umich.edu/" target="_blank">SHRG</a></b>
      </div>
    </span>
  `,
})
export class FooterComponent {

  currentYear: String;

  constructor() {
    this.currentYear = moment.utc(new Date()).format('YYYY');
  }

}
