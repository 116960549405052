import { ActivatedRoute, Router } from '@angular/router';
import { Component, AfterViewInit } from '@angular/core';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbSearchService, NbToastrService } from '@nebular/theme';

import { PlotDefinition } from '@shared/classes/plot.definition';
import { SOService } from '@shared/services/solar-orbiter.service';
import { PlotService } from '@shared/services/plot.service';

@Component({
  selector: 'ngx-housekeeping-main',
  styleUrls: ['./housekeeping.component.scss'],
  templateUrl: './housekeeping.component.html',
  providers: [
    SOService,
  ],
})
export class HousekeepingComponent implements AfterViewInit {

  // search & filter variables
  public searchText: string = '';
  public filterType: string = 'All';

  // plot variables
  plots: Array<PlotDefinition>;

  // parameters
  params: any = {};

  // date variables
  ready: boolean;
  origin: string;
  searchStartDate: Date;
  searchEndDate: Date;

  // loading variables
  preview = {
    initialized: false,
    plots: [],
    placeholders: [],
    loading: false,
    pageToLoadNext: 1,
  };
  previewSize: number = 2;
  previewTotal: number;

  // toast variables
  types: NbComponentStatus[] = [
    'primary',
    'success',
    'info',
    'warning',
    'danger',
  ];

  constructor(private subsystem: String,
              private searchService: NbSearchService,
              private router: Router,
              private activeRoute: ActivatedRoute,
              private solarService: SOService,
              public plotService: PlotService) { }

  ngAfterViewInit(): void {
    // initialize searchText
    this.searchService.onSearchInput()
      .subscribe((data: any) => {
        this.searchText = data.term;
      });
    // initialize filterType
    this.plotService.onSearchFilterChange()
      .subscribe((data: any) => {
        this.filterType = data.name;
      })
    // initialize activeRoute
    this.activeRoute.queryParams
      .subscribe((params) => {
        // condi: check parameter change & update data
        if (this.params['selection'] != undefined && this.params != params) this.getData(this.searchStartDate, this.searchEndDate, this.subsystem);
        // reset: parameters
        this.params = params;
        // initialize searchDate
        this.plotService.searchDate$
          .subscribe((data) => {
            // initialize variables
            this.ready = data.ready;
            this.origin = data.origin;
            this.searchStartDate = new Date(data.searchDate);
            this.searchEndDate = new Date(data.searchDate);
            this.searchStartDate.setHours(
              (this.searchStartDate.getHours() - this.plotService.timeResolution[data.resolution]['resolution'])
            );
            // condi: wait for searchDate
            if (this.ready && this.origin == 'housekeeping' && !this.preview.initialized) this.getData(this.searchStartDate, this.searchEndDate, this.subsystem);
          });
      });
  }

  async getData(startDate, endDate, subsystem) {
    // reset: previewPlots + pageToLoadNext
    this.preview.plots = [];
    this.preview.pageToLoadNext = 1;
    // get: plotDefinitions
    var plotDefinitions = await this.solarService.getPlotsByPacketAndSubsystem('housekeeping', subsystem).toPromise();
    // condi: single selection is included (prompted by dashboard)
    if (this.params['selection'] != undefined) {
      // initialize plotID
      var plotID = this.params['selection'];
      // condi: check plotID length
      if (plotID.length == 24) {
        // filter: plotDefinitions based on selection
        plotDefinitions = plotDefinitions.filter((el) => {
          return el._id == plotID;
        });
        // load single
        this.loadPage(plotDefinitions, [startDate, endDate], 1);
      } else {
        // load default
        this.loadPage(plotDefinitions, [startDate, endDate], 2)
      }
    } else {
      // load default
      this.loadPage(plotDefinitions, [startDate, endDate], 2)
    }
  }

  loadPage(plotDefinitions: any, date: Array<any>, count: number) {
    // get: plot parameters
    const plotParameters = this.plotService.getPlotParameters(plotDefinitions);
    // get: housekeeping data & create points
    this.solarService.getHousekeeping(date[0], date[1], plotParameters).toPromise()
      .then((housekeeping) => {
        // initialize: plots
        this.plots = this.plotService.bindData(plotDefinitions, housekeeping);
        // initialize: preview
        this.previewTotal = plotDefinitions.length;
        this.plotService.load(count, this.plots)
          .subscribe((nextPlot) => {
            this.preview.initialized = true;
            this.preview.placeholders = [];
            this.preview.plots.push(...nextPlot);
            this.preview.loading = false;
            this.preview.pageToLoadNext++;
          });
      });
  }

  loadNext(plotData) {
    // condi: check loading
    if (plotData.loading || this.preview.plots.length == 0) return;
    // set: loading + placeholders
    plotData.loading = true;
    plotData.placeholders = new Array(this.previewSize);
    // service: load in data
    this.plotService.load(this.previewSize, this.plots)
      .subscribe((nextPlot) => {
        plotData.placeholders = [];
        plotData.plots.push(...nextPlot);
        plotData.loading = false;
        plotData.pageToLoadNext++;
      });
  }

}