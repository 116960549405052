import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import {
  NbActionsModule,
  NbButtonModule,
  NbPopoverModule,
  NbCardModule,
  NbDatepickerModule,
  NbTabsetModule,
  NbUserModule,
  NbRadioModule,
  NbSelectModule,
  NbListModule,
  NbIconModule,
  NbInputModule,
} from '@nebular/theme';

import { ThemeModule } from '../@theme/theme.module';

// refresh import
import { RefreshComponent } from './components/_refresh/refresh.component';

// control imports
import { PreviewControlComponent } from './components/controls/preview/preview-control.component';
import { DashControlComponent } from './components/controls/dashboard/dash-control.component';

// plot imports
import { DashboardPlotComponent } from './components/plots/dashboard/dashboard.component';
import { ContinuousPlotComponent } from './components/plots/continuous/continuous.component';
import { DensityPlotComponent } from './components/plots/density/density.component';
import { DiscretePlotComponent } from './components/plots/discrete/discrete.component';

@NgModule({
  imports: [
    NbActionsModule,
    NbButtonModule,
    NbPopoverModule,
    NbCardModule,
    NbDatepickerModule,
    NbTabsetModule,
    NbUserModule,
    NbRadioModule,
    NbSelectModule,
    NbListModule,
    NbIconModule,
    NbInputModule,
    ReactiveFormsModule,
    ThemeModule,
  ],
  declarations: [
    DashControlComponent,
    PreviewControlComponent,
    ContinuousPlotComponent,
    DashboardPlotComponent,
    DensityPlotComponent,
    DiscretePlotComponent,
    RefreshComponent,
  ],
  exports: [
    ThemeModule,
    DashControlComponent,
    PreviewControlComponent,
    ContinuousPlotComponent,
    DashboardPlotComponent,
    DensityPlotComponent,
    DiscretePlotComponent,
    RefreshComponent,
  ],
})
export class SharedModule { }
