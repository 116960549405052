import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import { NbSearchService } from '@nebular/theme';

import { LowLatencyComponent } from '../_main/low-latency.component';

import { SOService } from '@shared/services/solar-orbiter.service';
import { PlotService } from '@shared/services/plot.service';

@Component({
  selector: 'ngx-low-latency-ratios',
  styleUrls: ['../_main/low-latency.component.scss'],
  templateUrl: '../_main/low-latency.component.html',
  providers: [
    SOService,
  ],
})
export class LowLatencyRatiosComponent extends LowLatencyComponent {

  constructor(searchService: NbSearchService,
              router: Router,
              activeRoute: ActivatedRoute,
              solarService: SOService,
              plotService: PlotService)
  {
    super('ratio', searchService, router, activeRoute, solarService, plotService);
  }

}
