// import angular modules
import { Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { NbDateService } from '@nebular/theme';

import { PlotService } from '@shared/services/plot.service';
import { SOService } from '@shared/services/solar-orbiter.service';

var moment = require('moment');

@Component({
  selector: 'ngx-dash-control',
  templateUrl: './dash-control.component.html',
  styleUrls: ['./dash-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    SOService
  ],
})
export class DashControlComponent implements OnInit {

  @ViewChild("dashboardDateElm", {static: true}) dashboardDateElm: ElementRef;

  // dashboardDate variables
  ready: boolean;
  dashboardDate: Date;
  dashboardDateResolution: number;
  dashboardDateFormatted: String;

  // route varibles
  routeSubscription: any;
  location: string;

  constructor(private router: Router,
              private solarService: SOService,
              public plotService: PlotService,
              protected dateService: NbDateService<Date>) { }

  ngOnInit() {
    // initialize dashboardDate
    this.plotService.dashboardDate$
      .subscribe((data) => {
        // initialize variables
        this.ready = data.ready;
        this.dashboardDate = data.dashboardDate;
        this.dashboardDateResolution = data.resolution;
        // set: dashboardDate element
        this.dashboardDateFormatted = moment.utc(this.dashboardDate).format('MMM D, YYYY');
        this.dashboardDateElm.nativeElement.value = this.dashboardDateFormatted;
        // condi: initialize latest housekeeping date
        if (!this.ready) this.initializeDashboardDate();
      });
  }

  initializeDashboardDate(): void {
    // get: latest housekeeping date
    this.solarService.getLatestHousekeeping()
      .subscribe((date) => {
        // update: dashboardDate
        this.updateDashboardDate({
          ready: true,
          dashboardDate: new Date(date),
          resolution: this.dashboardDateResolution
        });
      });
  }

  updateDashboard(action?: string): void {
    // initialize variables
    var dashboardDate = new Date(this.dashboardDate);
    var timeResolution = this.plotService.timeResolution[this.dashboardDateResolution].resolution;
    // switch on action
    switch(action) {
      case 'next':
        dashboardDate.setHours(
          (dashboardDate.getHours() + timeResolution)
        );
        break;
      case 'previous':
        dashboardDate.setHours(
          (dashboardDate.getHours() - timeResolution)
        );
        break;
      case 'enter':
        dashboardDate = new Date(this.dashboardDateElm.nativeElement.value);
        dashboardDate.setUTCHours(23);
        dashboardDate.setUTCMinutes(59);
        break;
      default:
        dashboardDate.setUTCHours(23);
        dashboardDate.setUTCMinutes(59);
        break;
    }
    // update dashboardDate
    this.updateDashboardDate({
      ready: true,
      dashboardDate: dashboardDate,
      resolution: this.dashboardDateResolution
    });
    // reload component
    this.location = this.router.url;
    this.router.navigateByUrl(`/pages/refresh`, { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(`${this.location}`);
    });
  }

  updateDashboardDate(data: any): void {
    this.plotService.updateDashboardDate({
      ready: data.ready,
      dashboardDate: data.dashboardDate,
      resolution: data.resolution
    });
  }

}
