import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '@shared/services/auth.service';
import { SOService } from '@shared/services/solar-orbiter.service';
import { Router } from '@angular/router';

var moment = require('moment');

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [ { title: 'Log out' } ];

  latestHousekeeping = {
    'date': 'Date Unavailable',
    'diff': [],
  };
  latestSensor = {
    'date': 'Date Unavailable',
    'diff': [],
  }
  latestLowLatency = {
    'date': 'Date Unavailable',
    'diff': [],
  }
  latestConfiguration: any;

  green: string = '#5cb85c';
  yellow: string = '#f0ad4e';
  red: string = '#ff3d71'

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private solarService: SOService,
              private router: Router,
              public auth: AuthService)
  {
    this.auth.localAuthSetup();
  }

  ngOnInit() {
    // handle theme
    this.themeService.changeTheme(this.currentTheme);
    this.currentTheme = this.themeService.currentTheme;
    // handle user profile
    this.auth.userProfile$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: any) => {
        // determine if user is approved
        this.solarService.getApprovedUsersConfig()
          .subscribe((approved: Array<any>) => {
            // create user property
            this.user = {
              name: (user.given_name + ' ' + user.family_name), 
              picture: user.picture || 'assets/images/dev.png'
            }
            // condi: determine if approved
            var found = false;
            for(var i = 0; i < approved.length; i++) {
              if (approved[i] == user.nickname) {
                found = true;
                break;
              }
            };
            // logout and redirect
            if (found == false) {
              this.auth.logout();
              this.router.navigateByUrl('login');
            }
          });

      })
    // handle user menu
    this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'user-menu'),
        map(({ item: { title } }) => title)
      )
      .subscribe(title => {
        switch(title) {
          case 'Log out': {
            this.auth.logout();
          }
        }
      });
    // handle latest housekeeping date
    this.solarService.getLatestHousekeeping()
      .pipe(takeUntil(this.destroy$))
      .subscribe((date: Date) => {
        this.latestHousekeeping['date'] = moment(date).format('YYYY-MM-DD hh:mm');
        // calculate day difference
        this.latestHousekeeping['diff'] = this.getDateDifference(date);
        // update: latestHousekeeping value
        this.latestHousekeeping['date'] = this.latestHousekeeping['date'] + this.latestHousekeeping['diff'][0];
      });
    // handle latest sensor date
    this.solarService.getLatestSensor()
      .pipe(takeUntil(this.destroy$))
      .subscribe((date: Date) => {
        this.latestSensor['date'] = moment(date).format('YYYY-MM-DD hh:mm');
        // calculate day difference
        this.latestSensor['diff'] = this.getDateDifference(date);
        // update: latestSensor value
        this.latestSensor['date'] = this.latestSensor['date'] + this.latestSensor['diff'][0];
      });
    // handle latest low latency date
    this.solarService.getLatestLowLatency()
      .pipe(takeUntil(this.destroy$))
      .subscribe((date: Date) => {
        this.latestLowLatency['date'] = moment(date).format('YYYY-MM-DD hh:mm');
        // calculate day difference
        this.latestLowLatency['diff'] = this.getDateDifference(date);
        // update: latestSensor value
        this.latestLowLatency['date'] = this.latestLowLatency['date'] + this.latestLowLatency['diff'][0];
      });
    // handle latest packet classes
    this.solarService.getLatestPacketConfig()
      .pipe(takeUntil(this.destroy$))
      .subscribe((config: any) => {
        this.latestConfiguration = config;
      });
    // handle screensize
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);
    // handle theme change
    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  getDateDifference(date: Date): Array<any> {
    // initialize variables
    var today = new Date();
    var todayTime = today.getTime();
    var current = new Date(date);
    var currentTime = current.getTime();
    // calculate difference
    var difference = (todayTime - currentTime) / (1000 * 3600 * 24);
    // condi: determine plurality
    var plural = (difference >= 2 || Math.round(difference) == 0) ? true : false;
    return [` (${Math.round(difference)} day${plural ? 's' : ''})`, Math.round(difference)]
  }

  getPacketColor(packetDiff: any, packetType: string): string {
    // initialize variables
    var days = packetDiff[1];
    var color = 'inerhit';
    // condi: determine if undefined
    if (this.latestConfiguration == undefined) return color;
    // update: color based off of configuration
    color = days <= this.latestConfiguration[packetType]['green'] ? this.green : days <= this.latestConfiguration[packetType]['yellow'] ? this.yellow : days > this.latestConfiguration[packetType]['red'] ? this.red : 'inerhit';
    // switch: 
    return color;
  }

}
