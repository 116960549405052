// import angular modules
import { Component, OnInit, Input, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';

// import classes
import { PlotDefinition } from '@shared/classes/plot.definition';
import { PlotService } from '@shared/services/plot.service';

var moment = require('moment');

// import plotly
declare var Plotly: any;

@Component({
  selector: 'ngx-continuous-plot',
  templateUrl: './continuous.component.html',
  styleUrls: ['./continuous.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    PlotService,
  ],
})
export class ContinuousPlotComponent implements OnInit {

  @Input() plot: PlotDefinition;
  @ViewChild('continuousPlot', { static: true }) continuousPlot: ElementRef;

  // initialize component variables
  outOfRange: boolean = false;
  inconsistent: boolean = false;
  parameters: Array<String> = [];

  constructor(private plotService: PlotService) { }

  ngOnInit() {

    // initialize layout
    var layout = {
      height: 360,
      margin: {
        l: 15,
        r: 5,
        t: 20,
        b: 25,
        pad: 5,
      },
      plot_bgcolor: '#F7F9FC',
      autosize: true,
      xaxis: {
        automargin: true,
        gridcolor: '#FFF',
        title: 'TIME (UTC)',
        titlefont: {
          family: 'Roboto, sans-serif',
          size: 14,
        },
      },
      yaxis: {
        automargin: true,
        gridcolor: '#FFF',
        title: this.plot['continuous'].parameter_type.toUpperCase() != '' ? this.plot['continuous'].parameter_type.toUpperCase() + ' (' + this.plot['continuous'].unit + ')' : this.plot['continuous'].unit.toUpperCase(),
        titlefont: {
          family: 'Roboto, sans-serif',
          size: 14,
        },
      }
    };

    // initialize configuration
    var config = {
      responsive: true,
      modeBarButtonsToRemove: [
        'select2d',
        'lasso2d',
        'zoomIn2d',
        'zoomOut2d',
        'hoverClosestCartesian',
        'hoverCompareCartesian',
        'toggleSpikelines',
      ],
      displaylogo: false,
      showEditInChartStudio: false,
    };

    // initialize low-latency configuration
    if (this.plot['packet_type'] == 'low-latency') {
      // create: low-latency configuration
      var lowLatencyConfiguration = this.plotService.configureLowLatency(this.plot);
      // update: plotName
      this.plot['name'] = this.plot['name'] + ': ' + lowLatencyConfiguration['plotName'];
      // update: parameters
      this.parameters = lowLatencyConfiguration['parameters'];
      // set: shapes
      layout['shapes'] = lowLatencyConfiguration['shapes'];
      // condi: update inconsistent variable
      this.inconsistent = lowLatencyConfiguration['shapes'].length > 0 ? true : false;
    }

    // condi: add unit if exists
    if (this.plot['continuous'].unit == '') layout.yaxis['title'] = '';

    // condi: add logarithmic scaling
    if (this.plot['continuous'].log) {
      // y-axis
      layout.yaxis['type'] = 'log';
      layout.yaxis['autorange'] = true;
    }

    // condi: set y-axis if exists
    if (this.plot['continuous']['y_min'] != undefined && this.plot['continuous']['y_max'] != undefined) {
      // initialize tickvals + ticktext
      var tickvals = [] ;
      var ticktext = [];
      // loop: y_min & y_max
      for (var i = this.plot['continuous']['y_min'], j = 0; i < this.plot['continuous']['y_max']; i = Math.pow(10, j), j++) {
        tickvals.push(j == 0 ? 0 : Math.pow(10,j));
        ticktext.push(j == 0 ? '0' : ('10' + `${j}`.sup()));
      }
      // set: yaxis
      layout.yaxis['tickvals'] = tickvals;
      layout.yaxis['ticktext'] = ticktext;
    }

    // initialize data
    var data = [];
    var empty = true;

    // switch to populate data
    switch(true) {
      // min, max & average
      case this.plot['continuous'].subtype == 'min-max-avg': {
        var avg = {
          name: 'Avg',
          y: this.plot.data.map((packet) => packet[this.plot['continuous'].avg]),
        };
        var min = {
          name: 'Min',
          y: this.plot.data.map((packet) => packet[this.plot['continuous'].min]),
        };
        var max = {
          name: 'Max',
          y: this.plot.data.map((packet) => packet[this.plot['continuous'].max]),
        };
        // initialize shapes & create range
        layout['shapes'] = [];
        layout.yaxis['range'] = [Number(this.plot['continuous']['red_min']), Number(this.plot['continuous']['red_max'])];
        // loop: push shape
        for (let i = 1; i <= 4; i++) {
          // initialize variables
          var color, y;
          // switch: on iterator
          switch(i) {
            case 1:
              color = 'red';
              y = (this.plot['continuous']['red_min'] != "NONE") ? Number(this.plot['continuous']['red_min']) : null;
              break;
            case 2:
              color = 'red';
              y = (this.plot['continuous']['red_max'] != "NONE") ? Number(this.plot['continuous']['red_max']) : null;
              break;
            case 3:
              color = 'yellow';
              y = (this.plot['continuous']['yel_min'] != "NONE") ? Number(this.plot['continuous']['yel_min']) : null;
              break;
            case 4:
              color = 'yellow';
              y = (this.plot['continuous']['yel_max'] != "NONE") ? Number(this.plot['continuous']['yel_max']) : null;
              break;
          }
          // initialize shape
          var shape = {
            type: 'line',
            xref: 'paper',
            yref: 'y',
            x0: 0,
            y0: y,
            x1: 1,
            y1: y,
            line: {
              color: color,
              width: 4,
              dash: 'dot',
            }
          }
          // condi: check for present values in shapes
          if ( shape.x0 != null && shape.x1 != null &&
               shape.y0 != null && shape.y1 != null) {
          // push shape
          layout['shapes'].push(shape);
          };
        };
        // push to data
        data = [avg, min, max];
        // stop block
        break;
      }
      // create: data with single parameter
      default: {
        data = [{
          y: this.plot.data.map((packet) => packet[this.plot['continuous'].parameter]),
        }];
        // stop block
        break;
      };
    }

    // push constant data properties
    data.map((set) => {
      set['x'] = this.plot.data.map((packet) => moment.utc(new Date(packet['TIMETAG_EPOCH_MSEC'])).format());
      set['type'] = 'scatter';
      set['mode'] = 'lines+markers';
      set['marker'] = {
        symbol: 'circle',
        size: 5,
      };
    });

    // check plot for missing data
    data.forEach((set) => {
      // condi: reset empty flag on found data
      if ((set.x.length != 0 || set.y.length != 0) && !set.y.includes(undefined)) {
        empty = false;
      };
      // condi: check for range exceeding red lims
      if (Math.min.apply(null, set.y) < Number(this.plot['continuous']['red_min']) || Math.max.apply(null, set.y) > Number(this.plot['continuous']['red_max'])) {
        this.outOfRange = true;
      };
    });

    // update missing plot information
    if (empty == true) {
      // remove x-axis & y-axis
      layout['xaxis']['visible'] = false;
      layout['yaxis']['visible'] = false;
      // add annotations
      layout['annotations'] = [{
        text: "No data available.",
        xref: 'paper',
        yref: 'paper',
        showarrow: false,
        font: {
          family: 'Roboto, sans-serif',
          size: 28
        }
      }];
      // remove any shapes
      delete layout['shapes'];
    }

    // initialize plot
    this.continuousPlot = Plotly.newPlot(
      this.continuousPlot.nativeElement,
      data,
      layout,
      config
    );

  }

}