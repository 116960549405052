/**
 * DashDefinition: class used to generate dashboard plot definitions
 */
export class DashDefinition {

  // general information
  _id: string;
  name: string;
  description: string;

  // plot details
  packet_type: string;
  subsystem: string;

  // dashboard details
  high_voltage?: Array<any>;
  low_voltage?: Array<any>;
  temperature?: Array<any>;
  states?: Array<any>;
  rates?: Array<any>;

  // data details
  data?: Array<Object>;

}