import { NgModule } from '@angular/core';
import { NbMenuModule } from '@nebular/theme';

import { ThemeModule } from '../@theme/theme.module';
import { PagesComponent } from './pages.component';

import { DashboardModule } from './dashboard/dashboard.module';

// housekeeping
import { CDHModule } from './housekeeping/cdh/cdh.module';
import { OperatingModeModule } from './housekeeping/operating-mode/operating-mode.module';
import { DebugModule } from './housekeeping/debug/debug.module';
import { DSCBModule } from './housekeeping/dscb/dscb.module';
import { DSCBMCPModule } from './housekeeping/dscb-mcp/dscb-mcp.module';
import { DSCBSSDModule } from './housekeeping/dscb-ssd/dscb-ssd.module';
import { FSWModule } from './housekeeping/fsw/fsw.module';
import { IRAPHVPSModule } from './housekeeping/irap-hvps/irap-hvps.module';
import { LVPSModule } from './housekeeping/lvps/lvps.module';
import { OPTOLinkModule } from './housekeeping/opto-link/opto-link.module';
import { PAHVPSModule } from './housekeeping/pa-hvps/pa-hvps.module';

// sensor
import { SensorRatesModule } from './sensor/rates/rates.module';
import { SensorSSDModule } from './sensor/ssd/ssd.module';

// low-latency
import { LowLatencyRatesModule } from './low-latency/rates/rates.module';
import { LowLatencyRatiosModule } from './low-latency/ratios/ratios.module';

// miscecllaneous
import { PagesRoutingModule } from './pages-routing.module';
import { MiscellaneousModule } from './miscellaneous/miscellaneous.module';


@NgModule({
  imports: [
    PagesRoutingModule,
    ThemeModule,
    NbMenuModule,
    DashboardModule,
    CDHModule,
    DebugModule,
    DSCBModule,
    DSCBMCPModule,
    DSCBSSDModule,
    FSWModule,
    IRAPHVPSModule,
    LVPSModule,
    OPTOLinkModule,
    PAHVPSModule,
    SensorRatesModule,
    SensorSSDModule,
    LowLatencyRatesModule,
    LowLatencyRatiosModule,
    OperatingModeModule,
    MiscellaneousModule,
  ],
  declarations: [
    PagesComponent,
  ],
})
export class PagesModule {
}
