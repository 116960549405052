import { Location } from '@angular/common';
import { AfterViewInit, Component } from '@angular/core';
import { NbSearchService } from '@nebular/theme';

import { DashDefinition } from '@shared/classes/dash.definition';
import { PlotService } from '@shared/services/plot.service';
import { SOService } from '@shared/services/solar-orbiter.service';

@Component({
  selector: 'ngx-dashboard',
  styleUrls: ['./dashboard.component.scss'],
  templateUrl: './dashboard.component.html',
  providers: [
    SOService,
  ],
})
export class DashboardComponent implements AfterViewInit {

  // search & filter variables
  public searchText: string = '';

  // plot variables
  plots: Array<DashDefinition>
  plotParameters: Array<String> = [];

  // date variables
  ready: boolean;
  dashboardStartDate: Date;
  dashboardEndDate: Date;

  constructor(private searchService: NbSearchService,
              private solarService: SOService,
              private location: Location,
              public plotService: PlotService) { }

  ngAfterViewInit(): void {
    // replace state for authentication
    this.location.replaceState('/pages/dashboard');
    // initialize searchText
    this.searchService.onSearchInput()
      .subscribe((data: any) => {
        this.searchText = data.term;
      });
    // initialize dashboardDate
    this.plotService.dashboardDate$
      .subscribe((data) => {
        // initialize variables
        this.ready = data.ready;
        this.dashboardStartDate = new Date(data.dashboardDate);
        this.dashboardEndDate = new Date(data.dashboardDate);
        this.dashboardStartDate.setHours(
          (this.dashboardStartDate.getHours() - this.plotService.timeResolution[data.resolution]['resolution'])
        );
        // condi: wait for dashboardDate
        if (this.ready) this.getData(this.dashboardStartDate, this.dashboardEndDate);
      });
  }

  async getData(startDate, endDate) {
    // get: dashboard definitions
    const dashboardDefinitions = await this.solarService.getPlotsBySubsystem('dashboard').toPromise();
    // get: dashboard parameters
    const dashboardParameters = this.plotService.getPlotParameters(dashboardDefinitions);
    // get: plot definitions
    const plotDefinitions = await this.solarService.getPlotsByParameter(dashboardParameters.join('&')).toPromise();
    // get: plot parameters
    this.plotParameters = this.plotService.getPlotParameters(plotDefinitions);
    // get: housekeeping
    const housekeeping = await this.solarService.getHousekeeping(startDate, endDate, this.plotParameters).toPromise();
    // get: sensor
    const sensor = await this.solarService.getSensor(startDate, endDate, this.plotParameters).toPromise();
    // bind: plots to packets + plot definitions
    this.plots = this.plotService.bindDashData(dashboardDefinitions, plotDefinitions, housekeeping, sensor);
  }

}
