import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import { NbSearchService } from '@nebular/theme';

import { HousekeepingComponent } from '../_main/housekeeping.component';

import { SOService } from '@shared/services/solar-orbiter.service';
import { PlotService } from '@shared/services/plot.service';

@Component({
  selector: 'ngx-hk-irap-hvps',
  styleUrls: ['../_main/housekeeping.component.scss'],
  templateUrl: '../_main/housekeeping.component.html',
  providers: [
    SOService,
  ],
})
export class IRAPHVPSComponent extends HousekeepingComponent {

  constructor(searchService: NbSearchService,
              router: Router,
              activeRoute: ActivatedRoute,
              solarService: SOService,
              plotService: PlotService)
  {
    super('irap-hvps', searchService, router, activeRoute, solarService, plotService);
  }

}
