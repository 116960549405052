import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';

var moment = require('moment');

@Injectable({
  providedIn: 'root',
})

export class SOService {
  swahisURL = environment.routeSWAHIS;

  constructor(private http: HttpClient) { }

  /**
   * Returns housekeeping packets by date range
   * @example /swa-his/housekeeping?startDate=2018-12-14&endDate=2018-12-15&STRUCTURE_ID&SEQUENCE_COUNT
   */
  getHousekeeping(startDate, endDate, parameters: Array<any>): Observable<any> {
    // ternary: initialize the parameters
    var params = parameters ? '&' + parameters.join('&') : '';
    // return
    return this.http.get(`${this.swahisURL}/housekeeping?startDate=${moment.utc(startDate).format()}&endDate=${moment.utc(endDate).format()}${params}`);
  };

  /**
   * Returns most recent date from housekeeping packets
   */
  getLatestHousekeeping(): Observable<any> {
    return this.http.get(`${this.swahisURL}/housekeeping/latest`);
  }

  /**
   * Returns sensor packets by date range
   * @example /swa-his/sensor?startDate=2018-12-14&endDate=2018-12-15&START_RATES_EQBINNED
   */
  getSensor(startDate, endDate, parameters: Array<any>): Observable<any> {
    // ternary: initialize the parameters
    var params = parameters ? '&' + parameters.join('&') : '';
    // return
    return this.http.get(`${this.swahisURL}/sensor?startDate=${moment.utc(startDate).format()}&endDate=${moment.utc(endDate).format()}${params}`);
  };

  /**
   * Returns most recent date from sensor packets
   */
  getLatestSensor(): Observable<any> {
    return this.http.get(`${this.swahisURL}/sensor/latest`);
  }

  /**
   * Returns low-latency packets by date range
   * @example /swa-his/low-latency?startDate=2018-12-14&endDate=2018-12-15&START_RATES_EQBINNED
   */
  getLowLatency(startDate, endDate, parameters: Array<any>): Observable<any> {
    // ternary: initialize the parameters
    var params = parameters ? '&' + parameters.join('&') : '';
    // return
    return this.http.get(`${this.swahisURL}/low-latency?startDate=${moment.utc(startDate).format()}&endDate=${moment.utc(endDate).format()}${params}`);
  };

  /**
   * Returns most recent date from low-latency packets
   */
  getLatestLowLatency(): Observable<any> {
    return this.http.get(`${this.swahisURL}/low-latency/latest`);
  }

  /**
   * Returns all plot definitions that match a packet query
   * @example /swa-his/plots/byPacket/housekeeping
   */
  getPlotsByPacketType(packetType): Observable<any> {
    return this.http.get(`${this.swahisURL}/plots/byPacket/${packetType}`);
  };

  /**
   * Returns all plot definitions by their parameter
   * @example /swa-his/plots/byParameter/PA_HVPS_VOLTAGE_MONITOR&DSCB_START_MCPVMON
   */
  getPlotsByParameter(parameters): Observable<any> {
    return this.http.get(`${this.swahisURL}/plots/byParameter/${parameters}`);
  };

  /**
   * Returns all plot definitions that match a subsystem query
   * @example /swa-his/plots/bySubsystem/dashboard
   */
  getPlotsBySubsystem(subsystem): Observable<any> {
    return this.http.get(`${this.swahisURL}/plots/bySubsystem/${subsystem}`);
  };

  /**
   * Returns all plot definitions that match a packet and subsystem query
   * @example /swa-his/plots/housekeeping/cdh
   */
  getPlotsByPacketAndSubsystem(packetType, packetSubsystem): Observable<any> {
    return this.http.get(`${this.swahisURL}/plots/${packetType}/${packetSubsystem}`);
  };

  /**
   * Returns a list of approved users for web application authentication
   */
  getApprovedUsersConfig(): Observable<any> {
    return this.http.get(`${this.swahisURL}/config/approvedUsers`);
  }

  /**
   * Returns an object with color parameters for latest packet values
   */
  getLatestPacketConfig(): Observable<any> {
    return this.http.get(`${this.swahisURL}/config/latestPackets`);
  }

}
